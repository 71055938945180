<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      dense
      class="d-print-none"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="dw-fredoka">MaggyDIY WIF-Editor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="state.authUser" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{ state.authUser.username }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="logout()">
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="!state.authUser"
        text
        @click="loginDialogIsVisible = true"
      >
        Anmelden
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
      <v-list-item
        link
        @click="$router.push('/')"
      >
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Startseite</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        @click="$router.push('/patterns')"
      >
        <v-list-item-icon>
          <v-icon>mdi-library-shelves</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Bibliothek</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :disabled="!state.pattern"
        link
        @click="$router.push('/settings')"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :disabled="!state.pattern"
        link
        @click="$router.push('/editor')"
      >
        <v-list-item-icon>
          <v-icon>mdi-grid</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Editor</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :disabled="!state.pattern"
        link
        @click="$router.push('/info')"
      >
        <v-list-item-icon>
          <v-icon>mdi-sigma</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Projektinformationen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :disabled="!state.pattern"
        link
        @click="$router.push('/keyboardHelp')"
      >
        <v-list-item-icon>
          <v-icon>mdi-help</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tastaturbelegung</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        link
        @click="$router.push('/about')"
      >
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Über / Impressum</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        @click="$router.push('/poweredBy')"
      >
        <v-list-item-icon>
          <v-icon>mdi-battery-charging</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Powered by</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-dialog
      v-model="loginDialogIsVisible"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline"
        >
          Login
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="loginUsername"
                label="Benutzername / EMail"
                type="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="loginPassword"
                label="Password"
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="loginDialogIsVisible = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="login()"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="state.asyncJobCount > 0">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
// import * as api from '@/api';
import state from '@/state';

export default {
  data: () => ({
    state,

    drawer: false,

    loginDialogIsVisible: false,
    loginUsername: '',
    loginPassword: '',
  }),

  methods: {
    login() {
      state.runAsync(async () => {
        await state.login(this.loginUsername, this.loginPassword);

        this.loginDialogIsVisible = false;
      });
    },

    logout() {
      state.runAsync(async () => {
        await state.logout();
      });
    },
  },
};
</script>

<style scoped>
.dw-fredoka {
  font-family: "Fredoka One";
  font-style: normal;
  font-weight: 400;
}
</style>