<template>
  <v-container>
    <v-row>
      <v-col class="text-h5">
        Tastaturbelegung
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Navigation
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Tab"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Zum nächsten Bereich springen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Shift"></key-cap-image>
        <key-cap-image large text="Tab"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Zum vorherigen Bereich springen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        Cursor-Tasten
      </v-col>
      <v-col cols="9" class="align-self-center">
        Im aktiven Bereich navigieren
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        Linksklick
      </v-col>
      <v-col cols="9" class="align-self-center">
        Cursor an entsprechende Stelle bewegen
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Direkte Manipulation
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Space"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Schaft / Tritt unter dem Cursor aktivieren oder deaktivieren
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="1"></key-cap-image>
        ..
        <key-cap-image text="0"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Schaft / Tritt / Farbe 1 .. 10 setzen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Shift"></key-cap-image>
        <key-cap-image text="1"></key-cap-image>
        ..
        <key-cap-image large text="Shift"></key-cap-image>
        <key-cap-image text="0"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Schaft / Tritt / Farbe 11 .. 20 setzen
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Größere Bereiche auswählen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Shift"></key-cap-image>
        Cursor-Tasten
      </v-col>
      <v-col cols="9" class="align-self-center">
        Mehrere Fäden / Schäfte / Tritte auswählen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image large text="Shift"></key-cap-image>
        Linksklick
      </v-col>
      <v-col cols="9" class="align-self-center">
        Auswahl bis zur entsprechenden Stelle erweitern
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Ausgewählte Bereiche manipulieren
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="X"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Auswahl ausschneiden
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="C"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Auswahl kopieren
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="V"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Auswahl einfügen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="F"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Schäfte / Tritte spiegeln
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="M"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Fäden spiegeln
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Sonstiges
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="Z"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Rückgängig
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="Y"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Wiederholen
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="+"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Ansicht vergrößern
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <key-cap-image text="-"></key-cap-image>
      </v-col>
      <v-col cols="9" class="align-self-center">
        Ansicht verkleinern
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KeyCapImage from '@/components/KeyCapImage.vue';

export default {
  components: {
    KeyCapImage,
  },
};
</script>

<style>

</style>