export function flatten(...args) {
  function flattenReducer(memo, arg) {
    if (Array.isArray(arg)) {
      memo = arg.reduce(flattenReducer, memo);
    } else {
      memo.push(arg);
    }
    return memo;
  }

  return flattenReducer([], args);
}

export function convertFromCentimeters(cm, unit) {
  let result;
  switch (unit.toLowerCase()) {
  case 'centimeters':
  case 'cm':
    result = cm;
    break;
  case 'inches':
    result = cm / 2.54;
    break;
  case 'yards':
    result = cm / 2.54 / 36;
    break;
  default:
    throw new Error(`Unsupported unit "${unit}"`);
  }

  return result;
}

export function convertToCentimeters(length, unit) {
  let result;
  switch (unit.toLowerCase()) {
  case 'centimeters':
  case 'cm':
    result = length;
    break;
  case 'inches':
    result = length * 2.54;
    break;
  case 'yards':
    result = length * 2.54 * 36;
    break;
  default:
    throw new Error(`Unsupported unit "${unit}"`);
  }

  return result;
}

export function isColorDark(color) {
  const md = /^#(..)(..)(..)$/.exec(color);
  let result;
  if (md) {
    const r = parseInt(md [1], 16);
    const g = parseInt(md [2], 16);
    const b = parseInt(md [3], 16);

    // Source: http://alienryderflex.com/hsp.html
    const brightness = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b);

    result = (brightness < 128);
  }
  return result;
}
