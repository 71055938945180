import Vue from 'vue';
import VueRouter from 'vue-router';

import About from '@/views/About.vue';
import Editor from '@/views/Editor.vue';
import Home from '@/views/Home.vue';
import Info from '@/views/Info.vue';
import KeyboardHelp from '@/views/KeyboardHelp.vue';
import Patterns from '@/views/Patterns.vue';
import PoweredBy from '@/views/PoweredBy.vue';
import Preview from '@/views/Preview.vue';
import Settings from '@/views/Settings.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/patterns',
    name: 'Patterns',
    component: Patterns
  },
  {
    path: '/patterns/:importUid',
    name: 'ImportPattern',
    component: Patterns,
    props: true,
  },
  {
    path: '/preview/:importUid',
    name: 'PreviewPattern',
    component: Preview,
    props: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/editor',
    name: 'Editor',
    component: Editor
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/keyboardHelp',
    name: 'KeyboardHelp',
    component: KeyboardHelp
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/poweredBy',
    name: 'PoweredBy',
    component: PoweredBy,
  }
];

const router = new VueRouter({
  routes
});

export default router;
