<template>
  <div>
    <canvas
      ref="canvas"
    ></canvas>
  </div>
</template>

<script>
import logger from '@/logger';

export default {
  props: {
    pattern: Object,
  },

  data: () => ({
    canvasWidth: 0,
    canvasHeight: 0,
    resizeListener: null,
  }),

  mounted() {
    this.resizeListener = () => {
      this.updateCanvas();
    };

    window.addEventListener('resize', this.resizeListener);

    this.updateCanvas();
  },

  destroyed() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  },

  methods: {
    updateCanvas() {
      const { pattern } = this;

      const width = this.$el.clientWidth;

      const draftLeft = 0;
      const draftRight = draftLeft + pattern.warpThreadCount;
      const treadlingLeft = draftRight + 1;
      const treadlingRight = treadlingLeft + pattern.treadleCount;
      const weftColorLeft = treadlingRight + 1;
      const weftColorRight = weftColorLeft + 1;

      const scale = Math.min(width / weftColorRight, 4);

      const warpColorTop = 0;
      const warpColorBottom = warpColorTop + 1;
      const threadingTop = warpColorBottom + 1;
      const threadingBottom = threadingTop + pattern.shaftCount;
      const draftTop = threadingBottom + 1;
      const draftBottom = draftTop + pattern.weftThreadCount;

      const cnv = this.$refs.canvas;

      const dpr = window.devicePixelRatio || 1;

      cnv.width = Math.floor(scale * weftColorRight * dpr);
      cnv.height = Math.floor(scale * draftBottom * dpr);
      cnv.style.width = `${scale * weftColorRight}px`;
      cnv.style.height = `${scale * draftBottom}px`;

      const ctx = cnv.getContext('2d');

      ctx.clearRect(-1, -1, cnv.width + 2, cnv.height + 2);

      ctx.save();

      ctx.scale(scale * dpr, scale * dpr);

      for (let i = 0; i < pattern.warpThreadCount; i++) {
        const thread = pattern.threading [i];
        const x = draftRight - 1 - i;

        if ((thread.color >= 1) && (thread.color <= pattern.colorCount)) {
          ctx.fillStyle = pattern.colors [thread.color - 1];
          ctx.fillRect(x, warpColorTop, 1, 1);
        }

        for (const shaft of thread.shafts) {
          if ((shaft >= 1) && (shaft <= pattern.shaftCount)) {
            const y = threadingBottom - shaft;

            ctx.fillStyle = 'black';
            ctx.fillRect(x, y, 1, 1);
          }
        }
      }

      for (let i = 0; i < pattern.treadleCount; i++) {
        const x = treadlingLeft + i;

        for (const shaft of pattern.tieUp [i].shafts) {
          if ((shaft >= 1) && (shaft <= pattern.shaftCount)) {
            const y = threadingBottom - shaft;

            ctx.fillStyle = 'black';
            ctx.fillRect(x, y, 1, 1);
          }
        }
      }

      for (let i = 0; i < pattern.weftThreadCount; i++) {
        const thread = pattern.treadling [i];
        const y = draftTop + i;

        for (const treadle of thread.treadles) {
          if ((treadle >= 1) && (treadle <= pattern.treadleCount)) {
            const x = treadlingLeft + treadle - 1;

            ctx.fillStyle = 'black';
            ctx.fillRect(x, y, 1, 1);
          }
        }

        if ((thread.color >= 1) && (thread.color <= pattern.colorCount)) {
          ctx.fillStyle = pattern.colors [thread.color - 1];
          ctx.fillRect(weftColorLeft, y, 1, 1);
        }
      }

      for (let i = 0; i < pattern.weftThreadCount; i++) {
        const weftThread = pattern.treadling [i];
        const y = draftTop + i;

        const shafts = [];
        for (const treadle of weftThread.treadles) {
          if ((treadle >= 1) && (treadle <= pattern.treadleCount)) {
            for (const shaft of pattern.tieUp [treadle - 1].shafts) {
              if ((shaft >= 1) && (shaft <= pattern.shaftCount) && !shafts.includes(shaft)) {
                shafts.push(shaft);
              }
            }
          }
        }

        for (let j = 0; j < pattern.warpThreadCount; j++) {
          const warpThread = pattern.threading [j];
          const x = draftRight - 1 - j;

          let warpOnTop = false;
          for (const shaft of shafts) {
            if (warpThread.shafts.includes(shaft)) {
              warpOnTop = true;
            }
          }

          const color = warpOnTop ? warpThread.color : weftThread.color;
          if ((color >= 1) && (color <= pattern.colorCount)) {
            ctx.fillStyle = pattern.colors [color - 1];
            ctx.fillRect(x, y, 1, 1);
          }
        }
      }
      ctx.restore();

      logger.debug({ ...pattern });
      logger.debug(scale);
    },
  },
};
</script>

<style>

</style>