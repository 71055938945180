<template>
  <v-container>
    <v-row class="mb-4">
      <v-col class="text-h5">
        Bibliothek
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="elevation-2">
        <v-row>
          <v-col>
            <v-list height="calc(100vh - 300px)" class="overflow-y-auto">
              <v-list-item
                v-for="(patternInfo, i) in state.patternInfos"
                :key="i"
                :class="(patternInfo.id === state.currentPatternId) ? 'dw-active' : ''"
                two-line
                link
                @click="selectPatternInfo(patternInfo)"
              >
                <v-list-item-icon><v-icon>mdi-file</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ patternInfo.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ new Date(patternInfo.lastSavedAt).toLocaleString() }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              block
              class="mb-2"
              @click="createNewPattern()"
            >Neues Muster erzeugen</v-btn>
            <v-btn
              block
              @click="importWifDialogVisible = true"
            >WIF-Datei importieren</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" class="elevation-2" v-if="state.currentPatternInfo">
        <v-row>
          <v-col cols="4" class="text-right font-weight-bold">
            Titel
          </v-col>
          <v-col cols="8">
            {{ state.currentPatternInfo.title }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-right font-weight-bold">
            Zuletzt geändert am
          </v-col>
          <v-col cols="8">
            {{ new Date(state.currentPatternInfo.lastSavedAt).toLocaleString() }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-right font-weight-bold">
          </v-col>
          <v-col cols="8">
            <v-btn
              v-if="state.authUser"
              block
              class="mb-2"
              @click="publishToSite()"
            >Auf maggydiy.de veröffentlichen</v-btn>
            <v-btn
              block
              class="mb-2"
              @click="exportWifFile()"
            >Als WIF-Datei exportieren</v-btn>
            <v-btn
              block
              class="mb-2"
              disabled
              @click="exportPdfFile()"
            >Als PDF-Datei exportieren</v-btn>
            <v-btn
              block
              color="red"
              dark
              @click="deleteCurrentPatternInfo()"
            >Löschen</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="importWifDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title>WIF-Datei importieren</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="wifFileInput"
            label="WIF-Datei"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="importWifDialogVisible = false"
          >Abbrechen</v-btn>
          <v-btn
            color="primary"
            @click="importWifFile"
          >Importieren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="publishWifDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title>WIF-Datei veröffentlicht</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>Die WIF-Datei wurde unter folgendem Link veröffentlicht:</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="publishedWifUrl"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="publishWifDialogVisible = false"
          >Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as api from '@/api';
import logger from '@/logger';
import state from '@/state';
import Wif from '@/helpers/Wif';

export default {
  props: {
    importUid: String,
  },

  data: () => ({
    state,

    importWifDialogVisible: false,
    wifFileInput: null,

    publishWifDialogVisible: false,
    publishedWifUrl: null,
  }),

  mounted() {
    logger.debug(this.importUid);

    if (this.importUid) {
      state.runAsync(async () => {
        const { pattern: patternString } = await api.getPatternByUid(this.importUid);

        const pattern = new Wif();
        pattern.fromText(patternString);

        const id = Date.now().toString();

        const patternInfo = {
          id,
          title: pattern.title,
          lastSavedAt: Date.now(),
        };

        state.patternInfos.push(patternInfo);
        state.currentPatternId = id;
        state.pattern = pattern;

        state.storePattern();
        state.storeCurrentPatternId();
      });
    }
  },

  methods: {
    selectPatternInfo(patternInfo) {
      state.currentPatternId = patternInfo.id;
      state.storeCurrentPatternId();
      state.restorePattern();
    },

    createNewPattern() {
      const id = Date.now().toString();

      const patternInfo = {
        id,
        title: 'Ohne Namen',
        lastSavedAt: Date.now(),
      };

      const pattern = new Wif();

      state.patternInfos.push(patternInfo);
      state.currentPatternId = id;
      state.pattern = pattern;

      state.storePattern();
      state.storeCurrentPatternId();
    },

    importWifFile() {
      state.runAsync(async () => {
        const fileContents = await new Promise((resolve, reject) => {
          const fr = new FileReader();

          fr.onerror = err => {
            reject(err);
          };

          fr.onload = () => {
            resolve(fr.result);
          };

          fr.readAsText(this.wifFileInput);
        });

        const pattern = new Wif();
        pattern.fromText(fileContents);

        const id = Date.now().toString();

        const patternInfo = {
          id,
          title: pattern.title,
          lastSavedAt: Date.now(),
        };

        state.patternInfos.push(patternInfo);
        state.currentPatternId = id;
        state.pattern = pattern;

        state.storePattern();
        state.storeCurrentPatternId();

        this.importWifDialogVisible = false;
      });
    },

    publishToSite() {
      state.runAsync(async () => {
        const { pattern } = state;

        const fileContents = pattern.toText();

        const { uid } = await api.publishPattern(state.authToken, fileContents);

        const { href } = this.$router.resolve({
          name: 'PreviewPattern',
          params: {
            importUid: uid,
          },
        });

        const absUrl = new URL(href, document.location);
        logger.debug(absUrl);

        this.publishedWifUrl = absUrl.toString();
        this.publishWifDialogVisible = true;
      });
    },

    exportWifFile() {
      const { pattern } = state;

      const fileContents = pattern.toText();

      const fileContentsBase64 = Buffer.from(fileContents).toString('base64');

      const url = `data:text/plain;base64,${fileContentsBase64}`;

      const anchorElement = document.createElement('a');
      anchorElement.href = url;
      anchorElement.download = `${pattern.title}.wif`;
      anchorElement.style = 'display: none';
      document.body.appendChild(anchorElement);
      anchorElement.click();
      anchorElement.remove();
    },

    exportPdfFile() {

    },

    deleteCurrentPatternInfo() {
      // FIXME(daniel): confirmation dialog
      const index = state.patternInfos.indexOf(state.currentPatternInfo);
      if (index >= 0) {
        state.patternInfos.splice(index, 1);
        state.currentPatternId = null;
        state.storePatternInfos();
        state.storeCurrentPatternId();
      }
    }
  },
};
</script>

<style>
.dw-active {
  background-color: #ccccff;
}
</style>