<template>
  <v-container>
    <div v-if="pattern">
      <v-row>
        <v-col class="text-h5">
          Projektinformationen
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h6">
          Allgemeines
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Titel
        </v-col>
        <v-col
          cols="9"
        >
          {{ title }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Autor
        </v-col>
        <v-col
          cols="9"
        >
          {{ author }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          EMail
        </v-col>
        <v-col
          cols="9"
        >
          {{ email }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Notizen
        </v-col>
        <v-col
          cols="9"
        >
          {{ notes }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h6">
          Webstuhl
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Anzahl Schäfte
        </v-col>
        <v-col
          cols="9"
        >
          {{ shaftCount }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Anzahl Tritte
        </v-col>
        <v-col
          cols="9"
        >
          {{ treadleCount }}
        </v-col>
      </v-row>
      <v-row
        v-for="info in heddlesByShaft"
        :key="info.id"
      >
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Schaft {{ info.nr }}:
        </v-col>
        <v-col
          cols="9"
        >
          {{ info.count }} Litzen
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-h6">
          Kette
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Anzahl Kettfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ warpThreadCount }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Abstand Kettfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ warpSpacingCm }} cm / {{ warpSpacingInches }} inches
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Dichte Kettfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ warpDensityPerInch }} epi / {{ warpDensityPerDm }} Fäden pro 10 cm
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Breite Webstück
        </v-col>
        <v-col
          cols="9"
        >
          {{ warpWidthCm }} cm / {{ warpWidthInches }} inches
        </v-col>
      </v-row>
      <v-row
        v-for="info in warpThreadsByColor"
        :key="info.id"
      >
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Farbe {{ info.nr }}: <span class="px-3" :style="info.style">{{ info.color }}</span>
        </v-col>
        <v-col
          cols="9"
        >
          {{ info.count }} Fäden / {{ info.lengthM }} m / {{ info.lengthYards }} yards
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h6">
          Schuss
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Anzahl Schussfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ weftThreadCount }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Abstand Schussfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ weftSpacingCm }} cm / {{ weftSpacingInches }} inches
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Dichte Schussfäden
        </v-col>
        <v-col
          cols="9"
        >
          {{ weftDensityPerInch }} ppi / {{ weftDensityPerDm }} Fäden pro 10 cm
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Länge Webstück
        </v-col>
        <v-col
          cols="9"
        >
          {{ weftLengthCm }} cm / {{ weftLengthInches }} inches / {{ weftLengthYards }} yards
        </v-col>
      </v-row>
      <v-row
        v-for="info in weftThreadsByColor"
        :key="info.id"
      >
        <v-col
          cols="3"
          class="text-right font-weight-bold"
        >
          Farbe {{ info.nr }}: <span class="px-3" :style="info.style">{{ info.color }}</span>
        </v-col>
        <v-col
          cols="9"
        >
          {{ info.count }} Fäden / {{ info.lengthM }} m / {{ info.lengthYards }} yards
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h6">
          Muster
        </v-col>
      </v-row>
      <v-row class="pb-10">
        <v-col>
          <WifViewer
            :pattern="pattern"
          ></WifViewer>
        </v-col>
      </v-row>
      <v-footer class="d-print-none" fixed>
        <v-spacer></v-spacer>
        <v-btn
          @click="importPattern()"
          color="green"
        >
          In Bibliothek importieren
        </v-btn>
        <v-spacer></v-spacer>
      </v-footer>
    </div>
  </v-container>
</template>

<script>
import * as api from '@/api';
import state from '@/state';
import {
  convertFromCentimeters,
  convertToCentimeters,
  isColorDark,
} from '@/utils';
import Wif from '@/helpers/Wif';

import WifViewer from '@/components/WifViewer';

export default {
  props: {
    importUid: String,
  },

  components: {
    WifViewer,
  },

  data: () => ({
    pattern: null,
    title: '',
    author: '',
    email: '',
    notes: '',
    shaftCount: '',
    treadleCount: '',
    warpThreadCount: '',
    warpSpacingCm: '',
    warpSpacingInches: '',
    warpDensityPerInch: '',
    warpDensityPerDm: '',
    warpWidthCm: '',
    warpWidthInches: '',
    weftThreadCount: '',
    weftSpacingCm: '',
    weftSpacingInches: '',
    weftDensityPerInch: '',
    weftDensityPerDm: '',
    weftLengthCm: '',
    weftLengthInches: '',
    weftLengthYards: '',
    heddlesByShaft: [],
    warpThreadsByColor: [],
    weftThreadsByColor: [],
  }),

  mounted() {
    state.runAsync(async () => {
      const { pattern: patternString } = await api.getPatternByUid(this.importUid);

      const pattern = new Wif();
      pattern.fromText(patternString);

      this.pattern = pattern;
      this.title = pattern.title;
      this.author = pattern.author;
      this.email = pattern.email;
      this.notes = pattern.notes;

      this.shaftCount = pattern.shaftCount;
      this.treadleCount = pattern.treadleCount;

      this.warpThreadCount = pattern.warpThreadCount;
      const warpSpacingCm = convertToCentimeters(pattern.warpSpacing, pattern.warpUnits);
      const warpSpacingInches = convertFromCentimeters(warpSpacingCm, 'inches');
      this.warpSpacingCm = warpSpacingCm.toFixed(3);
      this.warpSpacingInches = warpSpacingInches.toFixed(3);
      this.warpDensityPerInch = (1 / warpSpacingInches).toFixed(1);
      this.warpDensityPerDm = (10 / warpSpacingCm).toFixed(1);
      const warpWidthCm = (warpSpacingCm * this.warpThreadCount);
      this.warpWidthCm = warpWidthCm.toFixed(1);
      this.warpWidthInches = (warpSpacingInches * this.warpThreadCount).toFixed(1);

      this.weftThreadCount = pattern.weftThreadCount;
      const weftSpacingCm = convertToCentimeters(pattern.weftSpacing, pattern.weftUnits);
      const weftSpacingInches = convertFromCentimeters(weftSpacingCm, 'inches');
      const weftSpacingYards = convertFromCentimeters(weftSpacingCm, 'yards');
      this.weftSpacingCm = weftSpacingCm.toFixed(3);
      this.weftSpacingInches = weftSpacingInches.toFixed(3);
      this.weftDensityPerInch = (1 / weftSpacingInches).toFixed(1);
      this.weftDensityPerDm = (10 / weftSpacingCm).toFixed(1);
      const weftLengthCm = (weftSpacingCm * this.weftThreadCount);
      this.weftLengthCm = weftLengthCm.toFixed(1);
      this.weftLengthInches = (weftSpacingInches * this.weftThreadCount).toFixed(1);
      this.weftLengthYards = (weftSpacingYards * this.weftThreadCount).toFixed(1);

      const heddlesByShaft = [];
      for (let i = 0; i < pattern.shaftCount; i++) {
        heddlesByShaft.push({
          id: `shaft-${i}`,
          nr: i + 1,
          count: 0,
        });
      }

      for (const thread of pattern.threading) {
        for (const shaft of thread.shafts) {
          if ((shaft >= 1) && (shaft <= heddlesByShaft.length)) {
            const info = heddlesByShaft [shaft - 1];
            info.count += 1;
          }
        }
      }

      this.heddlesByShaft = heddlesByShaft.filter(info => info.count > 0);

      const warpThreadsByColor = pattern.colors.map((color, index) => {
        const isDark = isColorDark(color);

        return {
          id: `warp-${index}`,
          nr: index + 1,
          color,
          isDark,
          style: `background-color: ${color}; color: ${isDark ? '#ffffff' : '#000000'}`,
          count: 0,
          lengthCm: 0,
        };
      });

      for (const thread of pattern.threading) {
        const { color } = thread;
        if ((color >= 1) && (color <= warpThreadsByColor.length)) {
          const info = warpThreadsByColor [color - 1];
          info.count += 1;
          info.lengthCm += weftLengthCm;
        }
      }

      this.warpThreadsByColor = warpThreadsByColor.filter(info => info.count > 0).map(info => {
        const { lengthCm } = info;

        return {
          ...info,
          lengthM: (lengthCm / 100).toFixed(1),
          lengthYards: convertFromCentimeters(lengthCm, 'yards').toFixed(1),
        };
      });

      const weftThreadsByColor = pattern.colors.map((color, index) => {
        const isDark = isColorDark(color);

        return {
          id: `weft-${index}`,
          nr: index + 1,
          color,
          isDark,
          style: `background-color: ${color}; color: ${isDark ? '#ffffff' : '#000000'}`,
          count: 0,
          lengthCm: 0,
        };
      });

      for (const thread of pattern.treadling) {
        const { color } = thread;
        if ((color >= 1) && (color <= weftThreadsByColor.length)) {
          const info = weftThreadsByColor [color - 1];
          info.count += 1;
          info.lengthCm += warpWidthCm;
        }
      }

      this.weftThreadsByColor = weftThreadsByColor.filter(info => info.count > 0).map(info => {
        const { lengthCm } = info;

        return {
          ...info,
          lengthM: (lengthCm / 100).toFixed(1),
          lengthYards: convertFromCentimeters(lengthCm, 'yards').toFixed(1),
        };
      });
    });
  },

  methods: {
    importPattern() {
      const { importUid } = this;

      this.$router.push({
        name: 'ImportPattern',
        params: {
          importUid,
        },
      });
    },
  },
};
</script>

<style>

</style>