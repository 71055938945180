import Vue from 'vue';

import * as api from '@/api';
import logger from '@/logger';
import Wif from '@/helpers/Wif';

function genKey(suffix) {
  return `de.maggydiy.wifEditor.${suffix}`;
}

function genPatternKey(id) {
  return genKey(`pattern.${id}`);
}

const patternInfosKey = genKey('patternInfos');
const currentPatternIdKey = genKey('currentPatternId');
const authTokenKey = genKey('authToken');

const state = new Vue({
  data: () => ({
    patternInfos: [],
    currentPatternId: null,
    pattern: null,

    authToken: null,
    authUser: null,

    asyncJobCount: 0,
  }),

  computed: {
    currentPatternInfo() {
      const id = this.currentPatternId;
      return this.patternInfos.find(patternInfo => patternInfo.id === id);
    },
  },

  methods: {
    runAsync(fn) {
      async function runner() {
        this.asyncJobCount += 1;
        try {
          return await fn();
        } finally {
          this.asyncJobCount -= 1;
        }
      }

      runner.call(this).then(null, err => {
        logger.error(err);
      });
    },

    storePatternInfos() {
      const patternInfosString = JSON.stringify(this.patternInfos);

      localStorage.setItem(patternInfosKey, patternInfosString);
    },

    storeCurrentPatternId() {
      localStorage.setItem(currentPatternIdKey, this.currentPatternId);
    },

    storePattern() {
      if (this.pattern && this.currentPatternId) {
        const patternString = JSON.stringify(this.pattern);

        localStorage.setItem(genPatternKey(this.currentPatternId), patternString);

        const patternInfo = this.currentPatternInfo;
        if (patternInfo) {
          patternInfo.title = this.pattern.title;
          patternInfo.lastSavedAt = Date.now();

          this.storePatternInfos();
        }
      }
    },

    restorePattern() {
      const id = this.currentPatternId;
      if (id) {
        const patternString = localStorage.getItem(genPatternKey(id));
  
        const patternData = JSON.parse(patternString);
  
        const pattern = new Wif(patternData);
  
        state.pattern = pattern;
      }
    },

    async login(username, password) {
      const { token, user } = await api.login(username, password);

      this.authToken = token;
      this.authUser = user;

      this.storeAuthToken();
    },

    async logout() {
      this.authToken = null;
      this.authUser = null;

      this.storeAuthToken();
    },

    storeAuthToken() {
      const authTokenString = JSON.stringify(this.authToken);
      localStorage.setItem(authTokenKey, authTokenString);
    },
  },
});

function restoreState() {
  try {
    const patternInfosString = localStorage.getItem(patternInfosKey);
    if (patternInfosString) {
      state.patternInfos = JSON.parse(patternInfosString);
    }

    const currentPatternId = localStorage.getItem(currentPatternIdKey);
    if (currentPatternId) {
      state.currentPatternId = currentPatternId;

      state.restorePattern();
    }

    state.runAsync(async () => {
      try {
        const authTokenString = localStorage.getItem(authTokenKey) || 'null';

        const authToken = JSON.parse(authTokenString);

        const authUser = await api.getAuthUser(authToken);

        state.authToken = authToken;
        state.authUser = authUser;
      } catch (err) {
        logger.error(err);

        state.authToken = null;
        state.authUser = null;
      }
    });
  } catch (err) {
    logger.error(err);
  }
}

restoreState();

// FIXME(daniel): only for dev purposes!
window.dwState = state;

export default state;
