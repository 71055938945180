<template>
  <v-container>
    <v-row>
      <v-col
        class="text-h5 text--center"
      >
        Willkommen beim WIF-Editor von @MaggyDIY!
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Einen Überblick findest Du auf der <a href="https://www.maggydiy.de/#/wif-editor">WIF-Editor-Seite auf meiner Homepage</a>!
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>
