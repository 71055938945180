let nextJsonRpcId = 1;

async function performJsonRpc(method, params) {
  const id = nextJsonRpcId;
  nextJsonRpcId += 1;

  const httpRequestBody = {
    jsonrpc: '2.0',
    id,
    method,
    params,
  };

  const httpResponse = await fetch('/api/v1/jsonrpc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(httpRequestBody),
  });

  if (!httpResponse.ok) {
    throw new Error(`JSONRPC request returned status code ${httpResponse.status}`);
  }

  const httpResponseBody = await httpResponse.json();

  if (httpResponseBody.error) {
    throw new Error(httpResponseBody.error.message);
  }

  return httpResponseBody.result;
}

export async function login(username, password) {
  return await performJsonRpc('login', {
    username,
    password,
  });
}

export async function logout(token) {
  return await performJsonRpc('logout', {
    token,
  });
}

export async function getAuthUser(token) {
  return await performJsonRpc('getAuthUser', {
    token,
  });
}

export async function publishPattern(token, pattern) {
  return await performJsonRpc('publishPattern', {
    token,
    pattern,
  });
}

export async function getPatternByUid(uid) {
  return await performJsonRpc('getPatternByUid', {
    uid,
  });
}
