import '@fontsource/fredoka-one';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2e7d32',
      },
    },
  },
});
