import { flatten } from '@/utils';

// Information: http://www.mhsoft.com/wif/wif.html

export class IniValue {
  constructor(keyName, rawValue) {
    this.keyName = keyName;
    this.rawValue = rawValue;
    this.type = null;
    this.value = null;
  }

  get(type) {
    if (this.type === null) {
      let value;
      if (type === 'string') {
        value = this.rawValue;
      } else if (type === 'number') {
        value = Number.parseFloat(this.rawValue);
      } else if (type === 'numbers') {
        const parts = this.rawValue.split(',');
        value = parts.map(part => Number.parseFloat(part));
      } else {
        throw new Error(`Unexpected type "${type}"`);
      }
      this.type = type;
      this.value = value;
    }
    if (this.type !== type) {
      throw new Error(`Mixed type access`);
    }
    return this.value;
  }

  getString() {
    return this.get('string');
  }

  getNumber() {
    return this.get('number');
  }

  getNumbers() {
    return this.get('numbers');
  }

  getBoolean() {
    return this.get('boolean');
  }
}

export class IniSection {
  constructor(sectionName) {
    this.sectionName = sectionName;
    this.dataMap = new Map();
  }

  has(keyName) {
    return this.dataMap.has(keyName.toLowerCase());
  }

  get(keyName) {
    return this.dataMap.get(keyName.toLowerCase());
  }
}

class IniFile {
  constructor(content) {
    this.sectionMap = new Map();
    this.currentSection = null;

    const lines = content.split(/\r?\n/g);

    for (const line of lines) {
      if (/^\s*$/.test(line)) {
        // ignore empty lines
      } else if (line.startsWith(';')) {
        // ignore comments lines
      } else if (line.startsWith('[')) {
        const md = /^\[([^\]]+)\]$/.exec(line);
        if (md) {
          const sectionName = md [1];
          const section = new IniSection(sectionName);
          this.sectionMap.set(sectionName.toLowerCase(), section);
          this.currentSection = section;
        } else {
          throw new Error(`Unexpected line ${JSON.stringify(line)}`);
        }
      } else if (!this.currentSection) {
        throw new Error(`unexpected state`);
      } else {
        const md = /^([^=]+)=(.*)$/.exec(line);
        if (md) {
          const key = md [1].trim();
          const value = md [2];
          const wifValue = new IniValue(key, value);
          this.currentSection.dataMap.set(key.toLowerCase(), wifValue);
        } else {
          throw new Error(`Unexpected line ${JSON.stringify(line)}`);
        }
      }
    }

    this.currentSection = null;
  }

  get(sectionName) {
    return this.sectionMap.get(sectionName.toLowerCase());
  }
}

export default class Wif {
  constructor(options = {}) {
    this.title = options.title ?? 'Unnamed';
    this.author = options.author ?? '';
    this.email = options.email ?? '';
    this.notes = options.notes ?? '';
    this.colorCount = options.colorCount ?? 2;
    this.colors = options.colors ?? [ '#ffffff', '#000000' ];
    this.shaftCount = options.shaftCount ?? 4;
    this.treadleCount = options.treadleCount ?? 4;
    this.warpThreadCount = options.warpThreadCount ?? 60;
    this.warpUnits = options.warpUnits ?? 'centimeters';
    this.warpSpacing = options.warpSpacing ?? 0.212;
    this.warpThickness = options.warpThickness ?? 0.212;
    this.weftThreadCount = options.weftThreadCount ?? 60;
    this.weftUnits = options.weftUnits ?? 'centimeters';
    this.weftSpacing = options.weftSpacing ?? 0.212;
    this.weftThickness = options.weftThickness ?? 0.212;
    this.threading = options.threading ?? [];
    this.tieUp = options.tieUp ?? [];
    this.treadling = options.treadling ?? [];

    this.ensureIntegrity();
  }

  fromText(content) {
    const iniFile = new IniFile(content);

    const textSection = iniFile.get('TEXT');
    const title = textSection.has('Title') ? textSection.get('Title').getString() : '';
    const author = textSection.has('Author') ? textSection.get('Author').getString() : '';
    const email = textSection.has('EMail') ? textSection.get('EMail').getString() : '';

    const colorPaletteSection = iniFile.get('COLOR PALETTE');
    const colorCount = colorPaletteSection.get('Entries').getNumber();
    const colorRange = colorPaletteSection.get('Range').getNumbers();

    const colorTableSection = iniFile.get('COLOR TABLE');
    const colors = [];
    for (let nr = 1; nr <= colorCount; nr++) {
      const rawRgb = colorTableSection.get(`${nr}`).getNumbers();
      const rgb = rawRgb.map(rawValue => {
        const value = Math.round((rawValue - colorRange [0]) / (colorRange [1] - colorRange [0]) * 255);
        return `00${value.toString(16)}`.slice(-2);
      });
      const color = `#${rgb [0]}${rgb [1]}${rgb [2]}`;
      colors.push(color);
    }

    const weavingSection = iniFile.get('WEAVING');
    const shaftCount = weavingSection.get('Shafts').getNumber();
    const treadleCount = weavingSection.get('Treadles').getNumber();

    const warpSection = iniFile.get('WARP');
    const warpThreadCount = warpSection.get('Threads').getNumber();
    const warpColor = warpSection.get('Color').getNumber();
    const warpUnits = warpSection.get('Units').getString().toLowerCase();
    const warpSpacing = warpSection.get('Spacing').getNumber();
    const warpThickness = warpSection.get('Thickness').getNumber();

    const warpColorsSection = iniFile.get('WARP COLORS');

    const weftSection = iniFile.get('WEFT');
    const weftThreadCount = weftSection.get('Threads').getNumber();
    const weftColor = weftSection.get('Color').getNumber();
    const weftUnits = weftSection.get('Units').getString().toLowerCase();
    const weftSpacing = weftSection.get('Spacing').getNumber();
    const weftThickness = weftSection.get('Thickness').getNumber();

    const weftColorsSection = iniFile.get('WEFT COLORS');

    const threadingSection = iniFile.get('THREADING');
    const tieUpSection = iniFile.get('TIEUP');
    const treadlingSection = iniFile.get('TREADLING');

    const threading = [];
    for (let nr = 1; nr <= warpThreadCount; nr++) {
      const key = nr.toString();

      const shafts = threadingSection.get(key).getNumbers();

      let color;
      if (warpColorsSection && warpColorsSection.has(key)) {
        color = warpColorsSection.get(key).getNumber();
      } else {
        color = warpColor;
      }

      threading.push({
        shafts,
        color,
      });
    }

    const tieUp = [];
    for (let nr = 1; nr <= treadleCount; nr++) {
      const key = nr.toString();

      const shafts = tieUpSection.get(key).getNumbers();

      tieUp.push({
        shafts,
      });
    }

    const treadling = [];
    for (let nr = 1; nr <= weftThreadCount; nr++) {
      const key = nr.toString();

      const treadles = treadlingSection.get(key).getNumbers();

      let color;
      if (weftColorsSection && weftColorsSection.has(key)) {
        color = weftColorsSection.get(key).getNumber();
      } else {
        color = weftColor;
      }

      treadling.push({
        treadles,
        color,
      });
    }

    const notesArray = [];
    const notesSection = iniFile.get('NOTES');
    if (notesSection) {
      let nr = 1;
      while (notesSection.has(`${nr}`)) {
        const line = notesSection.get(`${nr}`).getString();
        notesArray.push(line);
        nr += 1;
      }
    }

    this.title = title;
    this.author = author;
    this.email = email;
    this.notes = notesArray.join('\n');
    this.colorCount = colorCount;
    this.colors = colors;
    this.shaftCount = shaftCount;
    this.treadleCount = treadleCount;
    this.warpThreadCount = warpThreadCount;
    this.warpUnits = warpUnits;
    this.warpSpacing = warpSpacing;
    this.warpThickness = warpThickness;
    this.weftThreadCount = weftThreadCount;
    this.weftUnits = weftUnits;
    this.weftSpacing = weftSpacing;
    this.weftThickness = weftThickness;
    this.threading = threading;
    this.tieUp = tieUp;
    this.treadling = treadling;

    this.ensureIntegrity();
  }

  toText() {
    const title = this.title || 'Unnamed';
    const colors = this.colors.slice(0, this.colorCount);
    const threading = this.threading.slice(0, this.warpThreadCount);
    const tieUp = this.tieUp.slice(0, this.treadleCount);
    const treadling = this.treadling.slice(0, this.weftThreadCount);

    function getDominantColor(threads) {
      const colorCounts = threads.reduce((memo, thread) => {
        const color = thread.color - 1;
        if ((color >= 0) && (color < colors.length)) {
          memo [color].count += 1;
        }
        return memo;
      }, colors.map((color, index) => ({
        color,
        index,
        count: 0,
      })));

      colorCounts.sort((l, r) => {
        let result = r.count - l.count;
        if (result === 0) {
          result = l.index - r.index;
        }
        return result;
      });

      return (colorCounts [0].index + 1);
    }

    const dominantWarpColor = getDominantColor(threading);
    const dominantWeftColor = getDominantColor(treadling);

    const lines = [
      '[WIF]',
      'Version=1.1',
      'Date=April 20, 1997',
      'Developers=wif@mhsoft.com',
      'Source Program=MaggyDIY.de WIF Editor',
      'Source Version=0.1',
      '',
      '[CONTENTS]',
      'COLOR PALETTE=true',
      'TEXT=true',
      this.notes ? 'NOTES=true' : [],
      'WEAVING=true',
      'WARP=true',
      'WEFT=true',
      'COLOR TABLE=true',
      'THREADING=true',
      'TIEUP=true',
      'TREADLING=true',
      'WARP COLORS=true',
      'WEFT COLORS=true',
      '',
      '[TEXT]',
      `Title=${title}`,
      this.author ? `Author=${this.author}` : [],
      this.email ? `EMail=${this.email}` : [],
      '',
      this.notes ? [
        '[NOTES]',
        this.notes.split('\n').map((line, index) => `${index + 1} = ${line}`),
        '',
      ] : [],
      '[WEFT COLORS]',
      treadling.map((thread, index) => {
        return ((thread.color !== dominantWeftColor) && (thread.color <= colors.length)) ? `${index + 1} = ${thread.color}` : [];
      }),
      '',
      '[WARP COLORS]',
      threading.map((thread, index) => {
        return ((thread.color !== dominantWarpColor) && (thread.color <= colors.length)) ? `${index + 1} = ${thread.color}` : [];
      }),
      '',
      '[THREADING]',
      threading.map((thread, index) => {
        return `${index + 1} = ${thread.shafts.join(',')}`;
      }),
      '',
      '[TIEUP]',
      tieUp.map((treadle, index) => {
        return `${index + 1} = ${treadle.shafts.join(',')}`;
      }),
      '',
      '[TREADLING]',
      treadling.map((thread, index) => {
        return `${index + 1} = ${thread.treadles.join(',')}`;
      }),
      '',
      '[WEAVING]',
      'Rising Shed=true',
      `Treadles=${this.treadleCount}`,
      `Shafts=${this.shaftCount}`,
      '',
      '[WARP]',
      `Units=${this.warpUnits}`,
      `Color=${dominantWarpColor}`,
      `Threads=${this.warpThreadCount}`,
      `Spacing=${this.warpSpacing}`,
      `Thickness=${this.warpThickness}`,
      '',
      '[WEFT]',
      `Units=${this.weftUnits}`,
      `Color=${dominantWeftColor}`,
      `Threads=${this.weftThreadCount}`,
      `Spacing=${this.weftSpacing}`,
      `Thickness=${this.weftThickness}`,
      '',
      '[COLOR TABLE]',
      colors.map((color, index) => {
        let r, g, b;
        if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
          r = parseInt(color.slice(1, 3), 16);
          g = parseInt(color.slice(3, 5), 16);
          b = parseInt(color.slice(5, 7), 16);
        } else {
          throw new Error(`Unexpected color "${color}"`);
        }
        return `${index + 1} = ${r},${g},${b}`;
      }),
      '',
      '[COLOR PALETTE]',
      'Range=0,255',
      `Entries=${this.colorCount}`,
      '',
    ];

    const content = flatten(lines).join('\n');

    return content;
  }

  ensureIntegrity() {
    while (this.colors.length < this.colorCount) {
      this.colors.push('#ffffff');
    }

    while (this.threading.length < this.warpThreadCount) {
      this.threading.push({
        shafts: [],
        color: 1,
      });
    }

    while (this.tieUp.length < this.treadleCount) {
      this.tieUp.push({
        shafts: [],
      });
    }

    while (this.treadling.length < this.weftThreadCount) {
      this.treadling.push({
        treadles: [],
        color: 1,
      });
    }

    for (const thread of this.threading) {
      if (thread.color < 1) {
        thread.color = 1;
      }
    }

    for (const thread of this.treadling) {
      if (thread.color < 1) {
        thread.color = 1;
      }
    }
  }
}
