// From RFC 5424:
//
// Numerical         Severity
// Code
//
//  0       Emergency: system is unusable
//  1       Alert: action must be taken immediately
//  2       Critical: critical conditions
//  3       Error: error conditions
//  4       Warning: warning conditions
//  5       Notice: normal but significant condition
//  6       Informational: informational messages
//  7       Debug: debug-level messages

const logLevel = 7;

const nop = function() {};

const console = window.console;

export default {
  error: (logLevel >= 3) ? console.error : nop,
  warn: (logLevel >= 4) ? console.warn : nop,
  log: (logLevel >= 5) ? console.log : nop,
  info: (logLevel >= 6) ? console.info : nop,
  debug: (logLevel >= 7) ? console.debug : nop,
  never: nop,
};
