<template>
  <v-container style="max-width: 600px">
    <v-row class="mb-4">
      <v-col
        class="text-h5"
      >
        Einstellungen
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
    >
      <v-tab>Allgemeines</v-tab>
      <v-tab>Webstuhl</v-tab>
      <v-tab>Kette</v-tab>
      <v-tab>Schuss</v-tab>
      <v-tab>Farben</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="elevation-2"
    >
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Titel
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Autor
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="author"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              EMail
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold">
              Notizen
            </v-col>
            <v-col cols="8">
              <v-textarea
                v-model="notes"
                outlined
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
            </v-col>
            <v-col cols="8">
              <v-btn
                @click="updatePattern"
                color="green"
                dark
              >Speichern</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Anzahl Schäfte
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="shaftCount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Anzahl Tritte
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="treadleCount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
            </v-col>
            <v-col cols="8">
              <v-btn
                @click="updatePattern"
                color="green"
                dark
              >Speichern</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Anzahl Kettfäden
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="warpThreadCount"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <v-btn
                @click="showCalculateThreadCountDialog('warpThreadCount', 'Breite', warpSpacing, warpUnits)"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Abstand Kettfäden
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="warpSpacing"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="warpUnits"
                :items="spacingUnits"
              ></v-select>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <v-btn
                @click="showCalculateSpacingDialog('warpSpacing', 'warpUnits')"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Breite Kette
            </v-col>
            <v-col cols="8">
              {{ warpWidths.cm.toFixed(1) }} cm <br> {{ warpWidths.inches.toFixed(1) }} inches
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Dichte Kette
            </v-col>
            <v-col cols="8">
              {{ warpDensity.epdm.toFixed(1) }} Fäden pro 10 cm <br> {{ warpDensity.epi.toFixed(1) }} Fäden pro Inch
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
            </v-col>
            <v-col cols="8">
              <v-btn
                @click="updatePattern"
                color="green"
                dark
              >Speichern</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Anzahl Schussfäden
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="weftThreadCount"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <v-btn
                @click="showCalculateThreadCountDialog('weftThreadCount', 'Länge', weftSpacing, weftUnits)"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Abstand Schussfäden
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="weftSpacing"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="weftUnits"
                :items="spacingUnits"
              ></v-select>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <v-btn
                @click="showCalculateSpacingDialog('weftSpacing', 'weftUnits')"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Länge Webstück
            </v-col>
            <v-col cols="8">
              {{ weftLengths.cm.toFixed(1) }} cm <br> {{ weftLengths.inches.toFixed(1) }} inches
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-right font-weight-bold align-self-center">
              Dichte Schuss
            </v-col>
            <v-col cols="8">
              {{ weftDensity.ppdm.toFixed(1) }} Fäden pro 10 cm <br> {{ weftDensity.ppi.toFixed(1) }} Fäden pro Inch
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
            </v-col>
            <v-col cols="8">
              <v-btn
                @click="updatePattern"
                color="green"
                dark
              >Speichern</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row
            v-for="i in state.pattern.colorCount"
            :key="i"
          >
            <v-col cols="3" class="text-right font-weight-bold align-self-center">Farbe {{ i }}</v-col>
            <v-col cols="7">
              <v-menu
                v-model="colorMenus [i - 1]"
                :close-on-content-click="false"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="state.pattern.colors [i - 1]"
                    :dark="isColorDark(state.pattern.colors [i - 1])"
                    v-text="state.pattern.colors [i - 1]"
                    v-bind="attrs"
                    v-on="on"
                    block
                  ></v-btn>
                </template>
                <v-color-picker
                  v-model="state.pattern.colors [i - 1]"
                  show-swatches
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="deleteColor(i - 1)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">Neue Farbe</v-col>
            <v-col cols="7">
              <v-color-picker
                v-model="newColor"
                show-swatches
              ></v-color-picker>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="addNewColor"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="threadCountCalculatorIsVisible"
      width="500"
    >
      <v-card>
        <v-card-title>
          Rechner
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="threadCountWidth"
                :label="threadCountLabel"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="threadCountWidthUnit"
                :items="threadCountWidthUnitItems"
                label="Einheit"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="threadCountCalculatorIsVisible = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="calculateThreadCount()"
          >
            Berechnen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="spacingCalculatorIsVisible"
      width="500"
    >
      <v-card>
        <v-card-title>
          Rechner
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="spacingDensity"
                label="Dichte"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="spacingDensityUnit"
                :items="spacingDensityUnitItems"
                label="Einheit"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="spacingCalculatorIsVisible = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="calculateSpacing()"
          >
            Berechnen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import logger from '@/logger';
import state from '@/state';

import {
  convertToCentimeters,
  convertFromCentimeters,
  isColorDark,
} from '@/utils';

const threadCountWidthUnitItems = [{
  text: 'cm',
  value: 'centimeters',
}, {
  text: 'inches',
  value: 'inches',
}, {
  text: 'yards',
  value: 'yards',
}];

const spacingUnits = [{
  text: 'cm',
  value: 'centimeters',
}, {
  text: 'inch',
  value: 'inches',
}];

const spacingDensityUnitItems = [{
  text: 'Fäden pro inch',
  value: 'perInch',
}, {
  text: 'Fäden pro 10 cm',
  value: 'perDm'
}];

export default {
  data: () => ({
    state,

    tab: 0,

    title: '',
    author: '',
    email: '',
    notes: '',

    shaftCount: 0,
    treadleCount: 0,

    spacingUnits,

    warpThreadCount: 0,
    warpSpacing: 0,
    warpUnits: 'centimeters',

    weftThreadCount: 0,
    weftSpacing: 0,
    weftUnits: 'centimeters',

    colorMenus: [],
    newColor: '#ffffff',

    threadCountKey: null,
    threadCountLabel: null,
    threadCountSpacingCm: 0,
    threadCountCalculatorIsVisible: false,
    threadCountWidth: '',
    threadCountWidthUnit: 'centimeters',
    threadCountWidthUnitItems,

    spacingKey: null,
    unitKey: null,
    spacingCalculatorIsVisible: false,
    spacingDensity: 14,
    spacingDensityUnit: 'perInch',
    spacingDensityUnitItems,
  }),

  mounted() {
    const { pattern } = state;

    this.title = pattern.title;
    this.author = pattern.author;
    this.email = pattern.email;
    this.notes = pattern.notes;
    this.shaftCount = pattern.shaftCount;
    this.treadleCount = pattern.treadleCount;
    this.warpThreadCount = pattern.warpThreadCount;
    this.warpSpacing = pattern.warpSpacing;
    this.warpUnits = pattern.warpUnits;
    this.weftThreadCount = pattern.weftThreadCount;
    this.weftSpacing = pattern.weftSpacing;
    this.weftUnits = pattern.weftUnits;

    while (this.colorMenus.length < pattern.colorCount) {
      this.colorMenus.push(false);
    }
  },

  computed: {
    warpWidths() {
      const spacingCm = convertToCentimeters(this.warpSpacing, this.warpUnits);

      const cm = this.warpThreadCount * spacingCm;
      const inches = convertFromCentimeters(cm, 'inches');

      return { cm, inches };
    },

    warpDensity() {
      const spacingCm = convertToCentimeters(this.warpSpacing, this.warpUnits);

      const epdm = 10 / spacingCm;
      const epi = 2.54 / spacingCm;

      return { epdm, epi };
    },

    weftLengths() {
      const spacingCm = convertToCentimeters(this.weftSpacing, this.weftUnits);

      const cm = this.weftThreadCount * spacingCm;
      const inches = cm / 2.54;

      return { cm, inches };
    },

    weftDensity() {
      const spacingCm = convertToCentimeters(this.weftSpacing, this.weftUnits);

      const ppdm = 10 / spacingCm;
      const ppi = 2.54 / spacingCm;

      return { ppdm, ppi };
    },
  },

  methods: {
    updatePattern() {
      const { pattern } = state;

      state.currentPatternInfo.title = this.title;

      pattern.title = this.title;
      pattern.author = this.author;
      pattern.email = this.email;
      pattern.notes = this.notes;
      pattern.shaftCount = +this.shaftCount;
      pattern.treadleCount = +this.treadleCount;
      pattern.warpThreadCount = +this.warpThreadCount;
      pattern.warpSpacing = +this.warpSpacing;
      pattern.warpThickness = +this.warpSpacing;
      pattern.warpUnits = this.warpUnits;
      pattern.weftThreadCount = +this.weftThreadCount;
      pattern.weftSpacing = +this.weftSpacing;
      pattern.weftThickness = +this.weftSpacing;
      pattern.weftUnits = this.weftUnits;

      pattern.ensureIntegrity();

      state.storePattern();
    },

    showCalculateThreadCountDialog(threadCountKey, threadCountLabel, spacing, units) {
      let spacingCm;
      switch (units) {
      case 'centimeters':
        spacingCm = +spacing;
        break;
      case 'inches':
        spacingCm = +spacing * 2.54;
        break;
      default:
        throw new Error(`Unexpected unit "${units}"`);
      }

      this.threadCountKey = threadCountKey;
      this.threadCountLabel = threadCountLabel;
      this.threadCountSpacingCm = spacingCm;
      this.threadCountCalculatorIsVisible = true;
    },

    calculateThreadCount() {
      let lengthCm;
      switch (this.threadCountWidthUnit) {
      case 'centimeters':
        lengthCm = +this.threadCountWidth;
        break;
      case 'inches':
        lengthCm = +this.threadCountWidth * 2.54;
        break;
      case 'yards':
        lengthCm = +this.threadCountWidth * 36 * 2.54;
        break;
      default:
        throw new Error(`Unexpected unit "${this.threadCountWidthUnit}"`);
      }

      const threadCount = Math.round(lengthCm / this.threadCountSpacingCm);

      this [this.threadCountKey] = threadCount;

      this.threadCountCalculatorIsVisible = false;
    },

    showCalculateSpacingDialog(spacingKey, unitKey) {
      this.spacingKey = spacingKey;
      this.unitKey = unitKey;
      this.spacingCalculatorIsVisible = true;
    },

    calculateSpacing() {
      let densityCm;
      switch (this.spacingDensityUnit) {
      case 'perDm':
        densityCm = +this.spacingDensity / 10;
        break;
      case 'perInch':
        densityCm = +this.spacingDensity / 2.54;
        break;
      default:
        throw new Error(`Unexpected unit "${this.spacingDensityUnit}"`);
      }

      this [this.spacingKey] = (1 / densityCm).toFixed(5);
      this [this.unitKey] = 'centimeters';

      this.spacingCalculatorIsVisible = false;
    },

    isColorDark(color) {
      return isColorDark(color);
    },

    addNewColor() {
      const { pattern } = state;

      const newColorCount = pattern.colorCount + 1;
      while (pattern.colors.length < newColorCount) {
        pattern.colors.push('#ffffff');
      }
      while (this.colorMenus.length < newColorCount) {
        this.colorMenus.push(false);
      }

      pattern.colors [newColorCount - 1] = this.newColor;
      pattern.colorCount = newColorCount;
    },

    deleteColor(index) {
      const { pattern } = state;

      pattern.colors.splice(index, 1);
      pattern.colorCount -= 1;
    },
  },

};
</script>

<style>

</style>