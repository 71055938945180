<template>
  <v-container fluid fill-height>
    <canvas
      ref="canvas"
      tabindex="1"
      @keydown="onCanvasKeydown"
      @click="onCanvasClick"
    ></canvas>
  </v-container>
</template>

<script>
import logger from '@/logger';
import state from '@/state';

const USE_CLIPBOARD = {};

const keys = '1234567890!"§$%&/()=';

export default {
  data: () => ({
    state,

    resizeListener: null,
    interval: null,

    areaInfoById: null,

    invalidated: true,

    zoomLevel: 3,
    coords: null,

    warpOffset: 0,
    weftOffset: 0,

    activeAreaId: 'threading',
    cursorI: 0,
    cursorJ: 0,
    cursorIndicesByAreaId: {},

    hasSelection: false,
    selectionStartI: 0,
    selectionStartJ: 0,

    clipboard: null,

    cursorTimer: 10,
    cursorInterval: 10,
    cursorState: true,

    storageTimer: 0,

    undoStack: [],
    redoStack: [],
    currentTransaction: null,
  }),

  mounted() {
    this.resizeListener = () => {
      this.updateCanvas();
    };

    window.addEventListener('resize', this.resizeListener);

    this.interval = setInterval(() => {
      this.handleInterval();
    }, 100);

    // this.initializeAreaInfo();

    this.refreshCursor();
    this.validate(true);

    this.$refs.canvas.focus();
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeListener);
    this.resizeListener = null;

    clearInterval(this.interval);
    this.interval = null;

    if (this.storageTimer > 0) {
      this.storageTimer = 0;
      state.storePattern();
    }
  },

  methods: {
    updateCanvas() {
      const { pattern } = state;

      const { ctx, cw, ch } = this.updateCanvasSize();

      const {
        // cw,
        // ch,
        // scale,
        wh,
        weftScrollRight,
        weftScrollLeft,
        // weftColorRight,
        weftColorLeft,
        // treadlingRight,
        treadlingLeft,
        draftRight,
        // maxVisibleWarpThreadCount,
        visibleWarpThreadCount,
        // maxWarpOffset,
        draftLeft,
        warpScrollTop,
        warpScrollBottom,
        warpColorTop,
        // warpColorBottom,
        // threadingTop,
        threadingBottom,
        draftTop,
        // maxVisibleWeftThreadCount,
        visibleWeftThreadCount,
        // maxWeftOffset,
        draftBottom,
      } = this.updateCoords(cw, ch);

      logger;

      const drawGrid = (wh >= 4);

      function setFillStyleForColorNr(colorNr) {
        const isValid = ((colorNr >= 1) && (colorNr <= pattern.colors.length));
        if (isValid) {
          ctx.fillStyle = pattern.colors [colorNr - 1];
        }
        return isValid;
      }

      ctx.strokeStyle = '#000';

      // -------- WARP MINIMAP --------
      const xMinimapStep = (draftRight - draftLeft) / pattern.warpThreadCount;
      let nextXMinimapStep = draftRight;
      for (let i = 0; i < pattern.warpThreadCount; i++) {
        const right = draftRight - Math.floor(i * xMinimapStep);
        if (right <= nextXMinimapStep) {
          const left = Math.min(draftRight - Math.floor((i + 1) * xMinimapStep), right - 1);
          if (setFillStyleForColorNr(pattern.threading [i].color)) {
            ctx.fillRect(left, warpScrollTop + 2, right - left + 1, warpScrollBottom - warpScrollTop - 4);
          }
          nextXMinimapStep = left;
        }
      }

      const warpViewPortLeft = draftRight - Math.floor((this.warpOffset + visibleWarpThreadCount + 1) * xMinimapStep);
      const warpViewPortRight = draftRight - Math.floor(this.warpOffset * xMinimapStep);
      ctx.beginPath();
      ctx.moveTo(warpViewPortLeft, warpScrollTop);
      ctx.lineTo(warpViewPortRight, warpScrollTop);
      ctx.lineTo(warpViewPortRight, warpScrollBottom);
      ctx.lineTo(warpViewPortLeft, warpScrollBottom);
      ctx.closePath();
      ctx.stroke();

      // -------- WARP BAR --------
      for (let i = 0; i < visibleWarpThreadCount; i++) {
        const thread = pattern.threading [i + this.warpOffset];

        const left = draftRight - wh - i * wh;

        if (setFillStyleForColorNr(thread.color)) {
          ctx.fillRect(left, warpColorTop, wh, wh);
        }

        if (drawGrid) {
          ctx.strokeRect(left, warpColorTop, wh, wh);
        }

        ctx.fillStyle = '#000';

        for (let j = 0; j < pattern.shaftCount; j++) {
          const top = threadingBottom - wh - j * wh;

          if (thread.shafts.includes(j + 1)) {
            ctx.fillRect(left, top, wh, wh);
          }

          if (drawGrid) {
            ctx.strokeRect(left, top, wh, wh);
          }
        }
      }

      // -------- TIEUP --------
      for (let i = 0; i < pattern.treadleCount; i++) {
        const treadle = pattern.tieUp [i];

        const left = treadlingLeft + i * wh;

        for (let j = 0; j < pattern.shaftCount; j++) {
          const top = threadingBottom - wh - j * wh;

          if (treadle.shafts.includes(j + 1)) {
            ctx.fillRect(left, top, wh, wh);
          }

          if (drawGrid) {
            ctx.strokeRect(left, top, wh, wh);
          }
        }
      }

      // -------- WEFT BAR --------
      for (let i = 0; i < visibleWeftThreadCount; i++) {
        const thread = pattern.treadling [i + this.weftOffset];

        const top = draftTop + i * wh;

        if (setFillStyleForColorNr(thread.color)) {
          ctx.fillRect(weftColorLeft, top, wh, wh);
        }

        if (drawGrid) {
          ctx.strokeRect(weftColorLeft, top, wh, wh);
        }

        ctx.fillStyle = '#000';

        for (let j = 0; j < pattern.treadleCount; j++) {
          const left = treadlingLeft + j * wh;

          if (thread.treadles.includes(j + 1)) {
            ctx.fillRect(left, top, wh, wh);
          }

          if (drawGrid) {
            ctx.strokeRect(left, top, wh, wh);
          }
        }
      }

      // -------- WEFT MINIMAP --------
      const yMinimapStep = (draftBottom - draftTop) / pattern.weftThreadCount;
      let nextYMinimapStep = draftTop;
      for (let i = 0; i < pattern.weftThreadCount; i++) {
        const top = draftTop + Math.floor(i * yMinimapStep);
        if (top >= nextYMinimapStep) {
          const bottom = Math.max(draftTop + Math.floor((i + 1) * yMinimapStep), top + 1);
          if (setFillStyleForColorNr(pattern.treadling [i].color)) {
            ctx.fillRect(weftScrollLeft + 2, top, weftScrollRight - weftScrollLeft - 4, bottom - top + 1);
          }
          nextYMinimapStep = bottom;
        }
      }

      const weftViewPortTop = draftTop + Math.floor(this.weftOffset * yMinimapStep);
      const weftViewPortBottom = draftTop + Math.floor((this.weftOffset + visibleWeftThreadCount + 1) * yMinimapStep);
      ctx.beginPath();
      ctx.moveTo(weftScrollLeft, weftViewPortTop);
      ctx.lineTo(weftScrollRight, weftViewPortTop);
      ctx.lineTo(weftScrollRight, weftViewPortBottom);
      ctx.lineTo(weftScrollLeft, weftViewPortBottom);
      ctx.closePath();
      ctx.stroke();

      // -------- DRAFT --------
      if (this.zoomLevel >= 3) {
        ctx.strokeStyle = '#000000';
        ctx.font = '12px Arial';
        ctx.fillStyle = '#000000';

        for (let i = 0; i < visibleWarpThreadCount; i++) {
          const warpThreadIndex = i + this.warpOffset;

          if ((warpThreadIndex % 10) === 9) {
            ctx.save();

            ctx.translate(draftRight - i * wh, draftTop);

            ctx.beginPath();
            ctx.moveTo(-(wh >> 1), 0);
            ctx.lineTo(-(wh >> 1), -wh);
            ctx.stroke();

            ctx.fillText(`${warpThreadIndex + 1}`, 0, -2);

            ctx.restore();
          }
        }

        for (let i = 0; i < visibleWeftThreadCount; i++) {
          const weftThreadIndex = i + this.weftOffset;

          if ((weftThreadIndex % 10) === 9) {
            ctx.save();

            ctx.translate(draftRight, draftTop + i * wh);
            ctx.rotate(Math.PI / 2);

            ctx.beginPath();
            ctx.moveTo((wh >> 1), 0);
            ctx.lineTo((wh >> 1), -wh);
            ctx.stroke();

            ctx.fillText(`${weftThreadIndex + 1}`, wh, -1);

            ctx.restore();
          }
        }
      }

      for (let i = 0; i < visibleWeftThreadCount; i++) {
        const weftThread = pattern.treadling [i + this.weftOffset];

        const top = draftTop + i * wh;

        const treadlingShafts = [];
        for (const treadle of weftThread.treadles) {
          if ((treadle >= 1) && (treadle <= pattern.treadleCount)) {
            const { shafts } = pattern.tieUp [treadle - 1];

            for (const shaft of shafts) {
              treadlingShafts.push(shaft);
            }
          }
        }

        for (let j = 0; j < visibleWarpThreadCount; j++) {
          const warpThread = pattern.threading [j + this.warpOffset];

          const left = draftRight - wh - j * wh;

          const threadingShafts = warpThread.shafts;

          if (treadlingShafts.length && threadingShafts.length) {
            let isRisen = false;
            for (const shaft of threadingShafts) {
              if (treadlingShafts.includes(shaft)) {
                isRisen = true;
                break;
              }
            }

            let isValid;
            if (isRisen) {
              isValid = setFillStyleForColorNr(warpThread.color);
            } else {
              isValid = setFillStyleForColorNr(weftThread.color);
            }

            if (isValid) {
              ctx.fillRect(left, top, wh, wh);
            }

            if (drawGrid) {
              ctx.strokeRect(left, top, wh, wh);
            }
          }
        }
      }

      // -------- CURSOR --------
      if (this.hasSelection) {
        const { x: startX, y: startY, minX, maxX, minY, maxY } = this.convertIndicesToCoords(this.activeAreaId, this.selectionStartI, this.selectionStartJ);
        const { x: endX, y: endY } = this.convertIndicesToCoords(this.activeAreaId, this.cursorI, this.cursorJ);

        const left = Math.max(Math.min(startX, endX), minX);
        const right = Math.min(Math.max(startX, endX) + wh, maxX);
        const top = Math.max(Math.min(startY, endY), minY);
        const bottom = Math.min(Math.max(startY, endY) + wh, maxY);

        ctx.beginPath();
        ctx.moveTo(left, top);
        ctx.lineTo(right, top);
        ctx.lineTo(right, bottom);
        ctx.lineTo(left, bottom);
        ctx.closePath();

        ctx.strokeStyle = '#ff0000';
        ctx.stroke();
      }

      if (this.cursorState) {
        const { x, y, isVisible } = this.convertIndicesToCoords(this.activeAreaId, this.cursorI, this.cursorJ);
        if (isVisible) {
          const n = 4;
          const whpn = wh / n;

          for (let dx = 0; dx < n; dx++) {
            for (let dy = 0; dy < n; dy++) {
              ctx.fillStyle = ((dx + dy) & 1) ? '#000000' : '#ffffff';
              ctx.fillRect(x + dx * whpn, y + dy * whpn, whpn, whpn);
            }
          }
        }
      }

      let cursorPos;
      switch (this.activeAreaId) {
      case 'warpColor':
      case 'threading':
      case 'treadling':
      case 'weftColor':
        cursorPos = `${this.cursorI + 1}`;
        break;
      case 'tieUp':
        cursorPos = null;
        break;
      default:
        throw new Error(`Unexpected area ID "${this.activeAreaId}"`);
      }

      if (cursorPos) {
        ctx.fillStyle = '#000000';
        ctx.font = '16px Arial';

        const { width } = ctx.measureText(cursorPos);
        ctx.fillText(cursorPos, weftScrollRight - width, warpScrollBottom);
      }

      // logger.debug({
      //   cursorI: this.cursorI,
      //   cursorJ: this.cursorJ,
      //   warpOffset: this.warpOffset,
      //   weftOffset: this.weftOffset,
      // });
    },

    updateCanvasSize() {
      const container = this.$el;
      const canvas = this.$refs.canvas;

      const cw = container.clientWidth - 60;
      const ch = container.clientHeight - 60;

      const dpr = window.devicePixelRatio;

      canvas.width = cw * dpr;
      canvas.height = ch * dpr;
      canvas.style = `width: ${cw}px; height: ${ch}px`;

      const ctx = canvas.getContext('2d');

      ctx.fillStyle = '#ffffff';
      ctx.fillRect(-1, -1, canvas.width + 2, canvas.height + 2);

      ctx.scale(dpr, dpr);
      ctx.translate(-0.5, 0.5);

      return { ctx, cw, ch };
    },

    updateCoords(cw, ch) {
      const { pattern } = state;

      const scale = (1 << this.zoomLevel);
      const wh = scale;

      // X-axis, starting right
      const weftScrollRight = cw;
      const weftScrollLeft = weftScrollRight - 24;

      const weftColorRight = weftScrollLeft - wh;
      const weftColorLeft = weftColorRight - wh;

      const treadlingRight = weftColorLeft - wh;
      const treadlingLeft = treadlingRight - wh * pattern.treadleCount;

      const draftRight = treadlingLeft - 2 * wh;

      const maxVisibleWarpThreadCount = Math.floor(draftRight / wh);
      const visibleWarpThreadCount = Math.min(maxVisibleWarpThreadCount, pattern.warpThreadCount);
      const maxWarpOffset = Math.max(0, pattern.warpThreadCount - visibleWarpThreadCount);

      const draftLeft = draftRight - wh * visibleWarpThreadCount;

      // Y-axis, starting at top
      const warpScrollTop = 0;
      const warpScrollBottom = warpScrollTop + 24;

      const warpColorTop = warpScrollBottom + wh;
      const warpColorBottom = warpColorTop + wh;

      const threadingTop = warpColorBottom + wh;
      const threadingBottom = threadingTop + wh * pattern.shaftCount;

      const draftTop = threadingBottom + 2 * wh;

      const maxVisibleWeftThreadCount = Math.floor((ch - draftTop) / wh);
      const visibleWeftThreadCount = Math.min(maxVisibleWeftThreadCount, pattern.weftThreadCount);
      const maxWeftOffset = Math.max(0, pattern.weftThreadCount - visibleWeftThreadCount);

      const draftBottom = draftTop + wh * visibleWeftThreadCount;

      this.coords = {
        cw,
        ch,
        scale,
        wh,
        weftScrollRight,
        weftScrollLeft,
        weftColorRight,
        weftColorLeft,
        treadlingRight,
        treadlingLeft,
        draftRight,
        maxVisibleWarpThreadCount,
        visibleWarpThreadCount,
        maxWarpOffset,
        draftLeft,
        warpScrollTop,
        warpScrollBottom,
        warpColorTop,
        warpColorBottom,
        threadingTop,
        threadingBottom,
        draftTop,
        maxVisibleWeftThreadCount,
        visibleWeftThreadCount,
        maxWeftOffset,
        draftBottom,
      };

      if (this.warpOffset > maxWarpOffset) {
        this.warpOffset = maxWarpOffset;
      }
      if (this.weftOffset > maxWeftOffset) {
        this.weftOffset = maxWeftOffset;
      }

      return this.coords;
    },

    handleInterval() {
      if (this.cursorTimer > 0) {
        this.cursorTimer -= 1;

        if (this.cursorTimer <= 0) {
          this.cursorTimer = this.cursorInterval;
          this.cursorState = !this.cursorState;
          this.validate(true);
        }
      }

      if (this.storageTimer > 0) {
        this.storageTimer -= 1;

        if (this.storageTimer <= 0) {
          this.storageTimer = 0;
          state.storePattern();
        }
      }
    },

    refreshCursor() {
      const interval = 10;

      this.cursorTimer = interval;
      this.cursorInterval = interval;
      this.cursorState = true;

      this.invalidate();
    },

    storePattern() {
      this.storageTimer = 100;
    },

    invalidate() {
      this.invalidated = true;
    },

    validate(force) {
      if (this.invalidated || force) {
        this.invalidated = false;
        this.updateCanvas();
      }
    },

    convertRelativeIndicesAndCoords(areaId, param1, param2) {
      switch (areaId) {
      case 'warpColor':
      case 'threading':
        return [ -param1, -param2 ];
      case 'tieUp':
        return [ param1, -param2 ];
      case 'treadling':
      case 'weftColor':
        return [ param2, param1 ];
      default:
        throw new Error(`Unexpected area ID "${areaId}"`);
      }
    },

    /// Returns the top left coordinate for the provides area and indices.
    convertIndicesToCoords(areaId, i, j) {
      const { coords } = this;

      let x, y, minX, maxX, minY, maxY;
      switch (areaId) {
      case 'warpColor':
        x = coords.draftRight - coords.wh - (i - this.warpOffset) * coords.wh;
        y = coords.warpColorTop;
        minX = coords.draftLeft;
        maxX = coords.draftRight;
        minY = coords.warpColorTop;
        maxY = coords.warpColorBottom;
        break;
      case 'threading':
        x = coords.draftRight - coords.wh - (i - this.warpOffset) * coords.wh;
        y = coords.threadingBottom - coords.wh - j * coords.wh;
        minX = coords.draftLeft;
        maxX = coords.draftRight;
        minY = coords.threadingTop;
        maxY = coords.threadingBottom;
        break;
      case 'tieUp':
        x = coords.treadlingLeft + i * coords.wh;
        y = coords.threadingBottom - coords.wh - j * coords.wh;
        minX = coords.treadlingLeft;
        maxX = coords.treadlingRight;
        minY = coords.threadingTop;
        maxY = coords.threadingBottom;
        break;
      case 'treadling':
        x = coords.treadlingLeft + j * coords.wh;
        y = coords.draftTop + (i - this.weftOffset) * coords.wh;
        minX = coords.treadlingLeft;
        maxX = coords.treadlingRight;
        minY = coords.draftTop;
        maxY = coords.draftBottom;
        break;
      case 'weftColor':
        x = coords.weftColorLeft;
        y = coords.draftTop + (i - this.weftOffset) * coords.wh;
        minX = coords.weftColorLeft;
        maxX = coords.weftColorRight;
        minY = coords.draftTop;
        maxY = coords.draftBottom;
        break;
      default:
        throw new Error(`Unexpected area ID "${areaId}"`);
      }

      const isVisible = ((x >= minX) && (x <= maxX) && (y >= minY) && (y <= maxY));

      return { x, y, minX, maxX, minY, maxY, isVisible };
    },

    convertCoordsToIndices(x, y) {
      const { coords } = this;

      let xArea;
      if ((x >= coords.draftLeft) && (x <= coords.draftRight)) {
        xArea = 'draft';
      } else if ((x >= coords.treadlingLeft) && (x <= coords.treadlingRight)) {
        xArea = 'treadles';
      } else if ((x >= coords.weftColorLeft) && (x <= coords.weftColorRight)) {
        xArea = 'color';
      } else if ((x >= coords.weftScrollLeft) && (x <= coords.weftScrollRight)) {
        xArea = 'scroll';
      }

      let yArea;
      if ((y >= coords.warpScrollTop) && (y <= coords.warpScrollBottom)) {
        yArea = 'scroll';
      } else if ((y >= coords.warpColorTop) && (y <= coords.warpColorBottom)) {
        yArea = 'color';
      } else if ((y >= coords.threadingTop) && (y <= coords.threadingBottom)) {
        yArea = 'shafts';
      } else if ((y >= coords.draftTop) && (y <= coords.draftBottom)) {
        yArea = 'draft';
      }

      const xDraftStep = Math.floor((coords.draftRight - x) / coords.wh) + this.warpOffset;
      const xTreadleStep = Math.floor((x - coords.treadlingLeft) / coords.wh);
      const yShaftStep = Math.floor((coords.threadingBottom - y) / coords.wh);
      const yDraftStep = Math.floor((y - coords.draftTop) / coords.wh) + this.weftOffset;

      let areaId, i, j, isInDraft = false;
      if ((xArea === 'draft') && (yArea === 'scroll')) {
        if (this.activeAreaId === 'threading') {
          areaId = 'threading';
          j = this.cursorJ;
        } else {
          areaId = 'warpColor';
          j = 0;
        }
        i = Math.floor((coords.draftRight - x) / (coords.draftRight - coords.draftLeft) * state.pattern.warpThreadCount);
        this.warpOffset = Math.min(Math.max(i - (coords.visibleWarpThreadCount >> 1), 0), coords.maxWarpOffset);
      } else if ((xArea === 'draft') && (yArea === 'color')) {
        areaId = 'warpColor';
        i = xDraftStep;
        j = 0;
      } else if ((xArea === 'draft') && (yArea === 'shafts')) {
        areaId = 'threading';
        i = xDraftStep;
        j = yShaftStep;
      } else if ((xArea === 'draft') && (yArea === 'draft')) {
        isInDraft = true;
        // areaId = 'draft';
        // i = yDraftStep;
        // j = xDraftStep;
      } else if ((xArea === 'treadles') && (yArea === 'shafts')) {
        areaId = 'tieUp';
        i = xTreadleStep;
        j = yShaftStep;
      } else if ((xArea === 'treadles') && (yArea === 'draft')) {
        areaId = 'treadling';
        i = yDraftStep;
        j = xTreadleStep;
      } else if ((xArea === 'color') && (yArea === 'draft')) {
        areaId = 'weftColor';
        i = yDraftStep;
        j = 0;
      } else if ((xArea === 'scroll') && (yArea === 'draft')) {
        if (this.activeAreaId === 'treadling') {
          areaId = 'treadling';
          j = this.cursorJ;
        } else {
          areaId = 'weftColor';
          j = 0;
        }
        i = Math.floor((y - coords.draftTop) / (coords.draftBottom - coords.draftTop) * state.pattern.weftThreadCount);
        this.weftOffset = Math.min(Math.max(i - (coords.visibleWeftThreadCount >> 1), 0), coords.maxWeftOffset);
      }

      // logger.debug({ x, y, xArea, yArea, areaId, i, j });

      return { areaId, i, j, isInDraft, xDraftStep, yDraftStep };
    },

    getMaxIndices(areaId) {
      const { pattern } = this.state;

      let maxI, maxJ;
      switch (areaId) {
      case 'warpColor':
        maxI = pattern.warpThreadCount;
        maxJ = 1;
        break;
      case 'threading':
        maxI = pattern.warpThreadCount;
        maxJ = pattern.shaftCount;
        break;
      case 'tieUp':
        maxI = pattern.treadleCount;
        maxJ = pattern.shaftCount;
        break;
      case 'treadling':
        maxI = pattern.weftThreadCount;
        maxJ = pattern.treadleCount;
        break;
      case 'weftColor':
        maxI = pattern.weftThreadCount;
        maxJ = 1;
        break;
      default:
        throw new Error(`Unexpected area ID "${areaId}"`);
      }

      return { maxI, maxJ };
    },

    startTransaction() {
      if (this.currentTransaction) {
        logger.debug('Canceling previous uncommitted transaction');
      }

      this.currentTransaction = {
        oldCursorI: this.cursorI,
        oldCursorJ: this.cursorJ,
        newCursorI: this.cursorI,
        newCursorJ: this.cursorJ,
        steps: [],
      };
    },

    commitTransaction() {
      if (this.currentTransaction) {
        const transaction = this.currentTransaction;
        this.currentTransaction = null;

        if (transaction.steps.length > 0) {
          transaction.newCursorI = this.cursorI;
          transaction.newCursorJ = this.cursorJ;

          if (this.undoStack.length >= 10) {
            this.undoStack.shift();
          }

          this.undoStack.push(transaction);
        }
      }
    },

    undoTransaction() {
      this.currentTransaction = null;

      const transaction = this.undoStack.pop();
      if (transaction) {
        for (const step of transaction.steps) {
          const { areaId, i, j, oldValue } = step;
          this.setValueAtIndices(areaId, i, j, oldValue);
        }

        this.cursorI = transaction.oldCursorI;
        this.cursorJ = transaction.oldCursorJ;

        this.redoStack.push(transaction);
      }
    },

    redoTransaction() {
      this.currentTransaction = null;

      const transaction = this.redoStack.pop();
      if (transaction) {
        for (const step of transaction.steps) {
          const { areaId, i, j, newValue } = step;
          this.setValueAtIndices(areaId, i, j, newValue);
        }

        this.cursorI = transaction.newCursorI;
        this.cursorJ = transaction.newCursorJ;

        this.undoStack.push(transaction);
      }
    },

    getValueAtIndices(areaId, i, j) {
      const { pattern } = this.state;

      let value;
      switch (areaId) {
      case 'warpColor':
        value = pattern.threading [i].color;
        break;
      case 'threading':
        value = pattern.threading [i].shafts.includes(j + 1);
        break;
      case 'tieUp':
        value = pattern.tieUp [i].shafts.includes(j + 1);
        break;
      case 'treadling':
        value = pattern.treadling [i].treadles.includes(j + 1);
        break;
      case 'weftColor':
        value = pattern.treadling [i].color;
        break;
      default:
        throw new Error(`Unexpected area ID "${areaId}"`);
      }

      return value;
    },

    setValueAtIndices(areaId, i, j, value) {
      const { pattern } = this.state;

      if (this.currentTransaction) {
        const oldValue = this.getValueAtIndices(areaId, i, j);

        this.currentTransaction.steps.push({
          areaId,
          i,
          j,
          oldValue,
          newValue: value,
        });
      }

      switch (areaId) {
      case 'warpColor':
        pattern.threading [i].color = value;
        break;
      case 'threading': {
        const { shafts } = pattern.threading [i];
        const index = shafts.indexOf(j + 1);
        if (value && (index < 0)) {
          shafts.splice(0, shafts.length);
          shafts.push(j + 1);
        } else if (!value && (index >= 0)) {
          shafts.splice(index, 1);
        }
        break;
      }
      case 'tieUp': {
        const { shafts } = pattern.tieUp [i];
        const index = shafts.indexOf(j + 1);
        if (value && (index < 0)) {
          shafts.push(j + 1);
          shafts.sort();
        } else if (!value && (index >= 0)) {
          shafts.splice(index, 1);
        }
        break;
      }
      case 'treadling': {
        const { treadles } = pattern.treadling [i];
        const index = treadles.indexOf(j + 1);
        if (value && (index < 0)) {
          treadles.splice(0, treadles.length);
          treadles.push(j + 1);
        } else if (!value && (index >= 0)) {
          treadles.splice(index, 1);
        }
        break;
      }
      case 'weftColor':
        pattern.treadling [i].color = value;
        break;
      default:
        throw new Error(`Unexpected area ID "${areaId}"`);
      }

      this.invalidate();
      this.storePattern();
    },

    moveCursorByRelativeCoordinates(dx, dy, withShiftKey) {
      const areaId = this.activeAreaId;

      const [ di, dj ] = this.convertRelativeIndicesAndCoords(areaId, dx, dy);

      return this.moveCursorByRelativeIndices(di, dj, withShiftKey);
    },

    moveCursorByRelativeIndices(di, dj, withShiftKey) {
      let newCursorI = this.cursorI + di;
      let newCursorJ = this.cursorJ + dj;

      return this.moveCursorToAbsoluteIndices(newCursorI, newCursorJ, withShiftKey);
    },

    moveCursorToAbsoluteIndices(newCursorI, newCursorJ, withShiftKey) {
      const areaId = this.activeAreaId;

      const { maxI, maxJ } = this.getMaxIndices(areaId);

      if (newCursorI < 0) {
        newCursorI = 0;
      } else if (newCursorI >= maxI) {
        newCursorI = maxI - 1;
      }
      if (newCursorJ < 0) {
        newCursorJ = 0;
      } else if (newCursorJ >= maxJ) {
        newCursorJ = maxJ - 1;
      }

      let changed = false;
      if (withShiftKey && !this.hasSelection) {
        this.hasSelection = true;
        this.selectionStartI = this.cursorI;
        this.selectionStartJ = this.cursorJ;
        changed = true;
      } else if (!withShiftKey && this.hasSelection) {
        this.hasSelection = false;
        changed = true;
      }
      if (this.cursorI !== newCursorI) {
        this.cursorI = newCursorI;
        changed = true;
      }
      if (this.cursorJ !== newCursorJ) {
        this.cursorJ = newCursorJ;
        changed = true;
      }

      // logger.debug({
      //   dx,
      //   dy,
      //   di,
      //   dj,
      //   maxI,
      //   maxJ,
      //   newCursorI,
      //   newCursorJ,
      //   changed,
      // });

      if (changed) {
        switch (areaId) {
        case 'warpColor':
        case 'threading':
          if (this.warpOffset > this.cursorI) {
            this.warpOffset = this.cursorI;
          } else if (this.warpOffset <= (this.cursorI - this.coords.visibleWarpThreadCount)) {
            this.warpOffset = this.cursorI - this.coords.visibleWarpThreadCount + 1;
          }
          break;
        case 'treadling':
        case 'weftColor':
          if (this.weftOffset > this.cursorI) {
            this.weftOffset = this.cursorI;
          } else if (this.weftOffset <= (this.cursorI - this.coords.visibleWeftThreadCount)) {
            this.weftOffset = this.cursorI - this.coords.visibleWeftThreadCount + 1;
          }
          break;
        case 'tieUp':
          // nop
          break;

        default:
          throw new Error(`Unexpected area ID "${areaId}"`);
        }

        this.refreshCursor();
      }

      return changed;
    },

    advanceCursor() {
      if (this.activeAreaId !== 'tieUp') {
        this.moveCursorByRelativeIndices(1, 0);
      }
    },

    changeArea(withShiftKey) {
      const areaIds = [
        'warpColor',
        'threading',
        'tieUp',
        'treadling',
        'weftColor',
      ];

      const currentAreaId = this.activeAreaId;
      const currentIndex = areaIds.indexOf(currentAreaId);
      const nextIndex = (currentIndex + (withShiftKey ? -1 : 1) + areaIds.length) % areaIds.length;
      const nextAreaId = areaIds [nextIndex];

      this.cursorIndicesByAreaId [currentAreaId] = {
        i: this.cursorI,
        j: this.cursorJ,
      };

      const newCursorIndices = this.cursorIndicesByAreaId [nextAreaId] || {};
      this.activeAreaId = nextAreaId;
      this.cursorI = newCursorIndices.i || 0;
      this.cursorJ = newCursorIndices.j || 0;
      this.hasSelection = false;

      this.refreshCursor();
    },

    toggleAtCursor() {
      const value = this.getValueAtIndices(this.activeAreaId, this.cursorI, this.cursorJ);
      if (typeof value === 'boolean') {
        this.setValueAtIndices(this.activeAreaId, this.cursorI, this.cursorJ, !value);
        this.advanceCursor();
      }
    },

    clearAtCursor() {
      const areaId = this.activeAreaId;

      const refValue = this.getValueAtIndices(areaId, this.cursorI, 0);
      let value;
      switch (typeof refValue) {
      case 'boolean':
        value = false;
        break;
      case 'number':
        value = 0;
        break;
      default:
        throw new Error(`Unexpected value type "${typeof refValue}"`);
      }

      const { maxJ } = this.getMaxIndices(areaId);
      for (let j = 0; j < maxJ; j++) {
        this.setValueAtIndices(areaId, this.cursorI, j, value);
      }

      this.advanceCursor();
    },

    getSelectionStartAndEndIndices() {
      const startI = this.hasSelection ? this.selectionStartI : this.cursorI;
      const startJ = this.hasSelection ? this.selectionStartJ : this.cursorJ;

      const minI = Math.min(this.cursorI, startI);
      const maxI = Math.max(this.cursorI, startI);
      const minJ = Math.min(this.cursorJ, startJ);
      const maxJ = Math.max(this.cursorJ, startJ);

      return { minI, maxI, minJ, maxJ };
    },

    getValuesInSelectionAsArray2D() {
      const { minI, maxI, minJ, maxJ } = this.getSelectionStartAndEndIndices();

      const areaId = this.activeAreaId;

      let result;
      if (!this.hasSelection && this.clipboard) {
        result = {
          minI: USE_CLIPBOARD,
          minJ: USE_CLIPBOARD,
          array: this.clipboard.map(values => values.slice(0)),
        };
      } else {
        const array = [];

        for (let i = minI; i <= maxI; i++) {
          const values = [];
          for (let j = minJ; j <= maxJ; j++) {
            values.push(this.getValueAtIndices(areaId, i, j));
          }
          array.push(values);
        }

        result = { minI, minJ, array };
      }

      return result;
    },

    setValuesFromArray2D(startI, startJ, array) {
      const areaId = this.activeAreaId;

      const { maxI, maxJ } = this.getMaxIndices(areaId);

      if ((startI === USE_CLIPBOARD) || (startJ === USE_CLIPBOARD)) {
        this.clipboard = array.map(values => values.slice(0));
      } else {
        for (let di = 0; di < array.length; di++) {
          const values = array [di];
          const i = startI + di;

          if (i < maxI) {
            for (let dj = 0; dj < values.length; dj++) {
              const j = (startJ + dj) % maxJ;

              this.setValueAtIndices(areaId, i, j, values [dj]);
            }
          }
        }
      }
    },

    cutToClipboard() {
      if (this.hasSelection) {
        const { minI, minJ, array } = this.getValuesInSelectionAsArray2D();
        this.clipboard = array;

        const refValue = array [0] [0];
        let value;
        switch (typeof refValue) {
        case 'boolean':
          value = false;
          break;
        case 'number':
          value = 0;
          break;
        default:
          throw new Error(`Unexpected type`);
        }

        const emptyArray = array.map(values => values.map(() => value));
        this.setValuesFromArray2D(minI, minJ, emptyArray);
      }
    },

    copyToClipboard() {
      if (this.hasSelection) {
        const { array } = this.getValuesInSelectionAsArray2D();
        this.clipboard = array;
      }
    },

    pasteFromClipboard() {
      if (this.clipboard) {
        this.setValuesFromArray2D(this.cursorI, this.cursorJ, this.clipboard);

        this.moveCursorByRelativeIndices(this.clipboard.length, 0);
      }
    },

    fillFromClipboard() {
      if (this.clipboard) {
        const areaId = this.activeAreaId;

        const { maxI } = this.getMaxIndices(areaId);

        let i = this.cursorI;
        while (i < maxI) {
          this.setValuesFromArray2D(i, this.cursorJ, this.clipboard);
          i += this.clipboard.length;
        }
      }
    },

    flipSelection() {
      const { minI, minJ, array } = this.getValuesInSelectionAsArray2D();

      for (let di = 0; di < array.length; di++) {
        array [di].reverse();
      }

      this.setValuesFromArray2D(minI, minJ, array);

      this.invalidate();
    },

    mirrorSelection() {
      const { minI, minJ, array } = this.getValuesInSelectionAsArray2D();

      array.reverse();

      this.setValuesFromArray2D(minI, minJ, array);

      this.invalidate();
    },

    onCanvasKeydown(ev) {
      this.startTransaction();

      let handled = true;
      switch (ev.key) {
      case 'ArrowLeft':
        this.moveCursorByRelativeCoordinates(-1, 0, ev.shiftKey);
        break;
      case 'ArrowRight':
        this.moveCursorByRelativeCoordinates(1, 0, ev.shiftKey);
        break;
      case 'ArrowUp':
        this.moveCursorByRelativeCoordinates(0, -1, ev.shiftKey);
        break;
      case 'ArrowDown':
        this.moveCursorByRelativeCoordinates(0, 1, ev.shiftKey);
        break;
      case 'Tab':
        this.changeArea(ev.shiftKey);
        break;
      case ' ':
        this.toggleAtCursor();
        break;
      case '_':
        this.clearAtCursor();
        break;
      case '-':
        if (this.zoomLevel > 0) {
          this.zoomLevel -= 1;
          this.invalidate();
        }
        break;
      case '+':
        if (this.zoomLevel < 4) {
          this.zoomLevel += 1;
          this.invalidate();
        }
        break;
      case 'x':
        this.cutToClipboard();
        break;
      case 'c':
        this.copyToClipboard();
        break;
      case 'v':
        this.pasteFromClipboard();
        break;
      case 'b':
        this.fillFromClipboard();
        break;
      case 'f':
        this.flipSelection();
        break;
      case 'm':
        this.mirrorSelection();
        break;
      case 'z':
        this.undoTransaction();
        break;
      case 'y':
        this.redoTransaction();
        break;
      default:
        if ((ev.key.length === 1) && keys.includes(ev.key)) {
          const index = keys.indexOf(ev.key);
          const tmpValue = this.getValueAtIndices(this.activeAreaId, this.cursorI, 0);
          if (this.activeAreaId === 'tieUp') {
            const value = this.getValueAtIndices(this.activeAreaId, this.cursorI, index);
            this.setValueAtIndices(this.activeAreaId, this.cursorI, index, !value);
          } else if (typeof tmpValue === 'boolean') {
            const { maxJ } = this.getMaxIndices(this.activeAreaId);
            for (let j = 0; j < maxJ; j++) {
              const value = this.getValueAtIndices(this.activeAreaId, this.cursorI, j);
              if (value) {
                this.setValueAtIndices(this.activeAreaId, this.cursorI, j, false);
              }
            }
            this.setValueAtIndices(this.activeAreaId, this.cursorI, index, true);
          } else if (typeof tmpValue === 'number') {
            this.setValueAtIndices(this.activeAreaId, this.cursorI, 0, index + 1);
          } else {
            throw new Error(`Unexpected value type "${typeof tmpValue}"`);
          }
          this.advanceCursor();
          this.invalidate();
        } else {
          handled = false;
        }
        break;
      }

      if (handled) {
        ev.preventDefault();
        ev.stopPropagation();
        this.validate();
      } else {
        logger.debug(ev);
      }

      this.commitTransaction();
    },

    onCanvasClick(ev) {
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = ev.clientX - rect.left;
      const y = ev.clientY - rect.top;
      // logger.debug({ x, y });

      const { areaId, i, j, isInDraft, xDraftStep, yDraftStep } = this.convertCoordsToIndices(x, y);
      if (areaId) {
        if (this.activeAreaId !== areaId) {
          this.activeAreaId = areaId;
          this.hasSelection = false;
          this.cursorI = i;
          this.cursorJ = j;
          this.refreshCursor();
        } else {
          this.moveCursorToAbsoluteIndices(i, j, ev.shiftKey);
        }
      } else if (isInDraft) {
        switch (this.activeAreaId) {
        case 'warpColor':
        case 'threading':
          this.moveCursorToAbsoluteIndices(xDraftStep, this.cursorJ, ev.shiftKey);
          break;
        case 'treadling':
        case 'weftColor':
          this.moveCursorToAbsoluteIndices(yDraftStep, this.cursorJ, ev.shiftKey);
          break;
        case 'tieUp':
          // nop
          break;
        default:
          throw new Error(`Unexpected area ID "${this.activeAreaId}"`);
        }
      }

      ev.preventDefault();
      ev.stopPropagation();
      this.validate();
    },
  },
};
</script>

<style>

</style>